<template>
	<div class="me">
		<div class="contact">
			<h2>私信</h2>
			<p>你可以去  <el-link type="primary" href="/link">链接</el-link> 里查看我的联系方式，或者在下方评论并勾选“私密评论”。</p>
		</div>
		
		<div class="faq">
			<h2>FAQ</h2>
			<h4>如何申请友链？</h4>
			<p>在评论区或私信网站必要信息即可，必要信息示例：</p>
			<div class="markdown-body hljs" v-highlight id="markdown-article" v-html='content'></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Me',
	data() {
		return {
			content: `<pre><code class="language-javascript hljs">
<span class="hljs-string">blogName: </span><span class="hljs-string">'KASUIEの次元' </span> #网站的名称
<span class="hljs-string">blogAddress: </span>'https://kasuie.top' #网站的地址
<span class="hljs-string">pictureAddress: </span>'https://i.loli.net/2020/11/19/hY1NXDLvmkRptTi.jpg' #网站的图标/或你的头像
<span class="hljs-string">detail: </span>'喜欢温柔的人' #网站的描述</code></pre>`,
			
		}
	},
}
</script>

<style scoped>
@import "/css/MWeb.css";
.me{
	width: 55%;
	color: var(--theme-color);
	margin: 2em auto;
	padding: 2em;
	text-align: start;
	background-color: rgba(255,255,255,.3);
}
.me h2{
	border-bottom: 1px solid grey;
	margin-bottom: .5em;
}
.darkApp .me{
	background-color: var(--theme-background-color)
}
@media screen and (max-width: 1400px) {
	.me{
		width: 65%;
	}
}
@media screen and (max-width: 770px) {
	.me{
		width: 90%;
		padding: 1em;
	}
}
</style>
