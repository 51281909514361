<template>
	<div class="whisper">
		<Nav></Nav>
		<TopCover :pageMsg='pageMsg'></TopCover>
		<Me></Me>
		<MessageC :messages='messages' @getMsg='getMessages'></MessageC>
	</div>
</template>

<script>
import TopCover from '@/components/TopCover.vue'
import Me from '@/components/Me.vue'
import MessageC from '@/components/Message.vue'
import Nav from '@/components/Nav.vue'
export default {
  metaInfo: {
      title: '留言板 - KASUIEの次元',
      meta: [
  	    {
  		    name: 'description',
              content: '留言板',
  	    },
          {
              name: 'keywords',
              content: '留言板，留言，评论，联系我，提问'
          }
    ]
  },
  name: 'Whisper',
  data() {
  	return {
  		pageMsg:{
  			title: '留言板',
  			imgUrl: 'https://i.loli.net/2021/07/16/UwkpdyfvRr1nVKj.png',
			article: ''
  		},
		messages: []
  	}
  },
  components: {
	TopCover,
	MessageC,
	Me,
	Nav
  },
  methods: {
	  getMessages(){
		  let that = this
		  this.axios.post('messages',{}).then(function(results){
			  that.messages = results.data
		  },function(err){
			  console.log(err)
		  })
	  }
  },
  created() {
  	this.getMessages()
	document.title = 'KASUIEの留言板'
	let title
	setTimeout(function() {
	   	title = document.title
	}, 1000);
	window.onfocus = function () {
		document.title = '恢复正常了...';
	    setTimeout(function() {
			document.title = title
		}, 1000);
	};
	window.onblur = function () {
		setTimeout(function() {
			document.title = '快回来~页面崩溃了'
		}, 5000);
	};
  },
  mounted() {
	document.body.style.overflow='hidden'
	setTimeout(() => {
		this.$store.commit('showLoading')
		document.body.style.overflow=''
	},2000)
  },
  destroyed() {
  	this.$store.commit('loading')
  }
}	
</script>

<style>
.whisper{
	position: relative;
}
</style>
